<template>
   <div class="main">
    <div class="chapter content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link to="/course"><span>Course</span></router-link> </a-breadcrumb-item>
        <a-breadcrumb-item> Chapter </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="add">
            <a-button type="primary" @click="showAddChapterModal"><PlusOutlined /> Add Chapter</a-button>
            <a-divider type="vertical" />
            <a-button type="primary">
              <router-link to="/course"><ArrowLeftOutlined />  Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <div class="chapter">
        <ListChapter :refreshChapterListKey="refreshChapterListKey" :chapter="chapter" />
      </div>
    </div>
    <AddEditChapter :showChapterModel="showChapterModel" @refreshChapterList="refreshChapter" />
   </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { HomeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'

import AddEditChapter from '@/components/agency/chapter/AddEditChapter.vue'
import ListChapter from '@/components/agency/chapter/ListChapter.vue'

export default defineComponent({
  components: {
    ListChapter,
    HomeOutlined,
    AddEditChapter,
    PlusOutlined,
    ArrowLeftOutlined
  },
  setup () {
    const showChapterModel = ref<number>(0)
    const showAddChapterModal = () => {
      showChapterModel.value++
    }
    const chapter = ref<{ _id: string; title: string; content: string; order: number; course: string }>()
    const refreshChapterListKey = ref<number>(0)
    const refreshChapter = async (data) => {
      chapter.value = data.data
      refreshChapterListKey.value++
    }
    return {
      showChapterModel,
      showAddChapterModal,
      refreshChapter,
      refreshChapterListKey,
      chapter
    }
  }
})
</script>
<style lang="scss">
.chapter {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #7EAF1A;
        border-color: #7EAF1A;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
