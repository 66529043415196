<template>
  <div class="curriculum">
    <a-row>
      <a-col
        class="chapters"
        :xs="24"
        :sm="24"
        :md="{ span: 14, offset: 5 }"
        :lg="{ span: 14, offset: 5 }"
        :xl="{ span: 14, offset: 5 }"
      >
        <template class="category" v-if="chapters.length == 0">
          <a-empty />
        </template>
        <div class="chapter" v-for="(chapter, index) in chapters" :key="chapter._id">
          <div class="icon">
            <PlayCircleOutlined />
          </div>
          <div class="title link" @click="viewChapter(chapter)">{{chapter.title}}</div>
          <div class="action">
            <a-tooltip>
            <template #title>Edit</template>
            <EditOutlined @click="showEditChapterModal(chapter, index)" />
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip>
            <template #title>Delete</template>
            <DeleteOutlined @click="showDeleteConfirm(chapter, index)"/>
            </a-tooltip>
          </div>
        </div>
      </a-col>
    </a-row>
    <AddEditChapter :showChapterModel="showChapterModel" :editChapter="editChapter" :editChapterData="editChapterData" @refreshChapterList="refreshChapter" />
  </div>
</template>

<script lang="ts">
import { createVNode, defineComponent, ref, onMounted, watch } from 'vue'
import { ExclamationCircleOutlined, PlayCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'

import AddEditChapter from '@/components/agency/chapter/AddEditChapter.vue'

import chapterService from '../../../services/chapter'
import router from '../../../router'

import IChapter from './../../../interface/chapter'

export default defineComponent({
  props: ['courseType', 'courseId', 'refreshChapterListKey', 'chapter'],
  components: {
    DeleteOutlined,
    EditOutlined,
    PlayCircleOutlined,
    AddEditChapter
  },
  setup (props) {
    const chapters = ref<Array<IChapter>>([])
    const editChapterData = ref<IChapter>()
    const showChapterModel = ref<number>(0)
    const editChapter = ref<boolean>(false)
    const route = useRoute()
    const showEditChapterModal = (chapter, index) => {
      editChapterData.value = chapter
      editChapterData.value.index = index
      editChapter.value = true
      showChapterModel.value++
    }
    const refreshChapter = async (data) => {
      chapters.value[data.index] = data.data
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const chapterDelete = async (chapter, index) => {
      try {
        await chapterService.deleteChapter(chapter._id)
        chapters.value.splice(index, 1)
        notify('Success', `${chapter.title} deleted`, 'success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (chapter, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          chapterDelete(chapter, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const getChapters = async () => {
      const courseId = route.params.id
      const responce = await chapterService.getCourseChapter(courseId)
      chapters.value = responce.data
    }
    const viewChapter = async (chapter) => {
      const courseId = route.params.id
      router.push(`/course/${courseId}/chapter/${chapter._id}`)
    }
    onMounted(() => {
      getChapters()
    })
    watch(() => props.refreshChapterListKey, () => {
      chapters.value.push(props.chapter)
    })
    return {
      showDeleteConfirm,
      notify,
      getChapters,
      chapters,
      editChapterData,
      showChapterModel,
      showEditChapterModal,
      chapterDelete,
      editChapter,
      refreshChapter,
      viewChapter
    }
  }
})
</script>

<style lang="scss">
  .curriculum {
    padding-bottom: 20px;
    .ant-row {
      padding: 0 20px;
      .chapters {
        min-height: 200px;
        background: #e2e9ee;
        border-radius: 10px;
        padding: 20px;
        .chapter {
          display: flex;
          background: #ffffff;
          border-radius: 5px;
          padding: 10px;
          margin-bottom: 10px;
          .icon {
            width: 10%;
          }
          .title {
            width: 70%;
            text-align: left;
          }
          .action {
            width: 20%;
          }
        }
      }
    }
  }
</style>
