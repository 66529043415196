
import { defineComponent, ref } from 'vue'
import { HomeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'

import AddEditChapter from '@/components/agency/chapter/AddEditChapter.vue'
import ListChapter from '@/components/agency/chapter/ListChapter.vue'

export default defineComponent({
  components: {
    ListChapter,
    HomeOutlined,
    AddEditChapter,
    PlusOutlined,
    ArrowLeftOutlined
  },
  setup () {
    const showChapterModel = ref<number>(0)
    const showAddChapterModal = () => {
      showChapterModel.value++
    }
    const chapter = ref<{ _id: string; title: string; content: string; order: number; course: string }>()
    const refreshChapterListKey = ref<number>(0)
    const refreshChapter = async (data) => {
      chapter.value = data.data
      refreshChapterListKey.value++
    }
    return {
      showChapterModel,
      showAddChapterModal,
      refreshChapter,
      refreshChapterListKey,
      chapter
    }
  }
})
