
import { defineComponent, reactive, ref, UnwrapRef, watch, onMounted } from 'vue'
import Editor from '@tinymce/tinymce-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { notification } from 'ant-design-vue'
import { useRoute } from 'vue-router'

import chapterService from '../../../services/chapter'

interface ChapterFormState {
  title: string;
  content: string;
  order: number;
  course: string;
  _id?: string;
}

export default defineComponent({
  props: ['showChapterModel', 'editChapter', 'editChapterData'],
  components: {
    Editor
  },
  setup (props, { emit }) {
    const timyCloudAPIKey = ref<string>(process.env.VUE_APP_EDITOR_API_KEY)
    const chapterFormRef = ref()
    const visible = ref<boolean>(false)
    const profile = JSON.parse(localStorage.profile)
    const userId = ref<string>(profile._id)
    const email = ref<string>(profile.email)
    const description = ref<string>('')
    const route = useRoute()
    const tokenURL = ref<string>(process.env.VUE_APP_API_URL + process.env.VUE_APP_JWT_TOKEN_Generate)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const chapterModel: UnwrapRef<{title: string; is_assesment: boolean; content: string; order: number; course: string }> = reactive({
      title: '',
      is_assesment: false,
      content: '',
      order: 0,
      course: ''
    })
    const createChapter = async (values: ChapterFormState) => {
      values.content = description.value
      values.course = route.params.id as string
      visible.value = false
      try {
        const responce = await chapterService.createChapter(values)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          chapterModel.title = ''
          chapterModel.is_assesment = false
          description.value = ''
          emit('refreshChapterList', responce)
          notify('Success', 'Chapter added successfully', 'success')
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const editChapter = async (values: ChapterFormState) => {
      values.content = description.value
      values.course = route.params.id as string
      values._id = props.editChapterData._id
      visible.value = false
      try {
        const responce = await chapterService.updateChapter(values, props.editChapterData._id)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          emit('refreshChapterList', { data: responce.data, index: props.editChapterData.index })
          notify('Success', 'Chapter updated successfully', 'success')
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const handleOk = () => {
      chapterFormRef.value
        .validate()
        .then(() => {
          if (props.editChapter) {
            editChapter(chapterModel)
          } else {
            createChapter(chapterModel)
          }
        })
        .catch((error: ValidateErrorEntity<ChapterFormState>) => {
          console.log('error', error)
        })
    }
    const handleCancel = () => {
      console.log('Clicked cancel button')
      visible.value = false
    }
    watch(() => props.showChapterModel, () => {
      visible.value = true
      if (props.editChapter) {
        description.value = props.editChapterData.content
        chapterModel.title = props.editChapterData.title
        chapterModel.is_assesment = props.editChapterData.is_assesment
        chapterModel.content = props.editChapterData.content
        chapterModel.course = props.editChapterData.course
        chapterModel.order = props.editChapterData.order
      }
    })
    onMounted(() => {
      console.log('Mounted')
    })
    return {
      visible,
      handleCancel,
      handleOk,
      userId,
      email,
      tokenURL,
      chapterModel,
      chapterFormRef,
      description,
      notify,
      timyCloudAPIKey
    }
  }
})
